

















import vue from "vue";

export default vue.extend({
  name: "b-switch",
  props: {
    elements: Array,
    value: String,
  },
  methods: {
    onChange(element: string) {
      console.log(element, this.value);
      if (element !== this.value) {
        this.$emit("onChange", element);
      }
    },
  },
});
