



































































































import Vue from "vue";
import BSwitch from "@/components/ui/b-switch.vue";
import ReservationsTable from "@/components/reservations-table.vue";
import reservationsService from "@/api/reservations";
import addWeeks from "date-fns/addWeeks";
import endOfWeek from "date-fns/endOfWeek";
import startOfWeek from "date-fns/startOfWeek";
import isBefore from "date-fns/isBefore";
import authService from "@/api/auth";
import { Reservation } from "@/models/reservation";
import { Roles } from "@/constants";
import isSameDay from "date-fns/isSameDay";
import isSameWeek from "date-fns/isSameWeek";
import { reservationMutated } from "@/events/reservation-mutated";
import parseISO from "date-fns/parseISO";
import ReservationModal from "@/components/ReservationModal.vue";

export default Vue.extend({
  name: "Home",
  components: { ReservationModal, ReservationsTable, BSwitch },
  mounted() {
    this.loadReservationsData();
    reservationMutated.$on("reservationMutated", this.onReservationUpdate);
  },
  beforeDestroy() {
    reservationMutated.$off("reservationMutated", this.onReservationUpdate);
  },
  watch: {
    customFrom() {
      this.loadReservationsData();
    },
    customTo() {
      this.loadReservationsData();
    },
  },
  methods: {
    onReservationUpdate() {
      this.loadReservationsData();
    },
    editReservation(reservation: Reservation) {
      console.log({ reservation });

      this.reservationToUpdate = reservation;
      this.$bvModal.show("edit-reservation");
    },
    async cancelReservation(reservation: Reservation) {
      await reservationsService.deleteReservation(reservation.id);
      await this.loadReservationsData();
    },
    async loadReservationsData() {
      const from = this.currentFilter === "Lapse" ? this.customFrom : this.from;
      const to = this.currentFilter === "Lapse" ? this.customTo : this.to;
      const today = new Date();
      this.isAdmin = !!authService
        .getLoggedUser()
        ?.role.includes(Roles.RESV_ADMIN);
      const reservations = (
        await (this.isAdmin
          ? reservationsService.all(from, to)
          : reservationsService.ownedReservations(from, to))
      ).data.map((reservation) => {
        const reservationDate = parseISO(reservation.date as string);
        let status;

        if (isSameDay(reservationDate, today)) {
          status = "Today";
        } else if (isBefore(reservationDate, today)) {
          status = "Past";
        } else if (isSameWeek(reservationDate, today)) {
          status = "Upcoming";
        } else {
          status = "Booked";
        }

        return { ...reservation, date: reservationDate, status };
      });

      const [due, notDue] = await Promise.all([
        new Promise<Reservation[]>((resolve) => {
          resolve(
            reservations.filter((reservation) => reservation.status === "Past")
          );
        }),
        new Promise<Reservation[]>((resolve) => {
          resolve(
            reservations.filter((reservation) => reservation.status !== "Past")
          );
        }),
      ]);

      this.dueReservations = due;
      this.reservations = notDue;
    },
  },
  data() {
    const from = startOfWeek(addWeeks(new Date(), -4));
    const to = endOfWeek(addWeeks(new Date(), 1));
    return {
      user: authService.getLoggedUser(),
      currentFilter: "Recent",
      filters: ["Recent", "Lapse"],
      customFrom: from,
      from,
      customTo: to,
      to,
      dueReservations: [] as Reservation[],
      reservations: [] as Reservation[],
      isAdmin: false,
      reservationToUpdate: (null as unknown) as Reservation,
    };
  },
});
