



































import vue from "vue";
import { Reservation } from "@/models/reservation";

export default vue.extend({
  name: "reservations-table",
  props: {
    disableActions: Boolean,
    reservations: Array,
    hideHeader: Boolean,
  },
  methods: {
    cancel(reservation: Reservation) {
      this.$emit("deleteReservation", reservation);
    },
    edit(reservation: Reservation) {
      this.$emit("editReservation", reservation);
    },
  },
  computed: {
    headerClassName: function () {
      return this.hideHeader ? "d-none" : undefined;
    },
  },
  data() {
    return {
      fields: [
        { key: "ownerFullName", label: "Member" },
        {
          key: "date",
          formatter: (date: Date) => {
            return date.toLocaleDateString();
          },
        },
        "notes",
        "status",
        { key: "actions", label: "Actions" },
      ],
    };
  },
});
